<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 10px">
      <el-tabs v-model="activeName" @tab-click="handleSearch">
        <el-tab-pane label="图片" name="1"></el-tab-pane>
        <el-tab-pane label="视频" name="2"></el-tab-pane>
      </el-tabs>
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="tableFrom"
        label-width="85px"
      >
        <el-form-item label="图片名称">
          <el-input
            v-model="tableFrom.name"
            placeholder="输入规格名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="handleSearch()"
            >搜索</el-button 
          >
          <el-button size="small" @click="handleReset()">重置</el-button>
          <el-button size="small" type="primary" @click="onAdd">新增</el-button>
          <!-- <el-button size="small" type="primary" @click="exportFun">导出</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never" body-style="border:none">
      <div class="mb20 acea-row" v-if="activeName == 1">
        <el-card
          style="width: 18%; margin-right: 20px; margin-bottom: 15px"
          v-for="(item, index) in tableData.data"
          :key="index"
        >
          <div class="imageBox">
            <img :src="item.url" class="image" />
          </div>
          <div style="font-size: 12px; height: 20px; margin-top: 10px">
            {{ item.name }}
          </div>
          <div style="padding: 14px">
            <div class="bottomBtn">
              <el-button type="text" class="button" @click="onEdit(item)"
                >修改名称</el-button
              >
              <el-button type="text" class="button" @click="copyLink(item.url)"
                >复制</el-button
              >
              <el-button type="text" class="button" @click="handleDelete(item)"
                >删除</el-button
              >
            </div>
          </div>
        </el-card>
      </div>
      <div class="mb20 acea-row" v-else>
        <el-card
          style="width: 280px; margin-right: 20px; margin-bottom: 15px"
          v-for="(item, index) in tableData.data"
          :key="index"
        >
          <div class="imageBox">
            <video
              controls
              controlsList="nodownload"
              :src="item.url"
              class="imageBox"
            />
          </div>
          <div style="padding: 14px">
            <div class="bottomBtn">
              <el-button type="text" class="button" @click="onEdit(item)"
                >修改名称</el-button
              >
              <el-button type="text" class="button" @click="copyLink(item.url)"
                >复制</el-button
              >
              <el-button type="text" class="button" @click="handleDelete(item)"
                >删除</el-button
              >
            </div>
          </div>
        </el-card>
      </div>

      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.page"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
        @submit="formSubmit"
      >
        <template slot="img">
          <FormImgUpload
            :url="form.img"
            @upload="upload"
            @uploadFile="uploadFile"
            :limit="9"
            :maxSize="5"
            :accept="
              activeName == 1
                ? 'image/jpeg,image/png,image/jpg'
                : 'video/mp4,video/MP4'
            "
            :multiple="true"
          />
          <div class="tipImg" v-if="activeName == 1">格式：jpeg,png,jpg</div>
          <div class="tipImg" v-else>格式：mp4,MP4</div>
        </template>
        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="submit(form)">确定</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      title="修改名称"
      :visible.sync="dialogVisibleName"
      width="600px"
      :before-close="handleCloseName"
    >
      <avue-form :option="optionName" v-model="formName" ref="formName">
        <template slot="menuForm">
          <el-button @click="handleCloseName">取消</el-button>
          <el-button type="primary" @click="submitName(formName)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>
 
<script>
import {
  sourceMaterialAdd,
  sourceMaterialADel,
  getSourceMaterialPageList,
  sourceMaterialUpdate,
} from "@/api/goods/goods";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      moren: require("@/assets/images/bjt.png"),
      activeName: "1",
      isChecked: false,
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 10,
        name: "",
      },
      title: "新增图片",
      dialogVisible: false,
      dialogVisibleName: false,
      formName: {},
      form: {
        img: [],
      },
      imgList: [],
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "图片",
            row: true,
            type: "text",
            prop: "img",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传图片",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
        ],
      },
      optionName: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "名称",
            prop: "name",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleSearch() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    /* 重置查询 */
    handleReset() {
      this.tableFrom.name = "";
      this.tableFrom.page = 1;
      this.$refs.tableFrom.resetFields();
      this.getList();
    },
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    async copyLink(url) {
      try {
        await navigator.clipboard.writeText(url);
        this.$message.success("链接已复制到剪贴板");
      } catch (err) {
        console.error("Failed to copy link: ", err);
        this.$message.error("复制链接失败");
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.image = res.image;
      // this.$nextTick(() => {
      //   this.form.image = res.path;
      // });
    },
    formSubmit() {},
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;
          let arrImg = [];
          this.imgList.map((item, index) => {
            if (this.form.img.includes(item.url)) {
              arrImg.push(item);
            }
          });
          sourceMaterialAdd({ list: arrImg }).then((res) => {
            // 获取数据成功后的其他操作
            // console.log(res);
            if (res.code === 200) {
              that.$message.success(res.msg);
              that.getList();
              that.handleClose();
              // done();
            } else {
              that.$message.error(res.msg);
              // done();
            }
          });
          this.form.type = 1;
          // done()
        }
      });
    },
    //导出
    // exportFun() {
    //   exportExcel('/core/sourceMaterial/exportExcel',{
    //     ...this.tableFrom,
    //     responseType: "blob",
    //     exportExcelName:'素材库'
    //   })
    // },
    submitName(form) {
      this.$refs.formName.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;
          sourceMaterialUpdate(this.formName).then((res) => {
            // 获取数据成功后的其他操作
            // console.log(res);
            if (res.code === 200) {
              that.$message.success(res.msg);
              that.getList();
              that.handleCloseName();
              // done();
            } else {
              that.$message.error(res.msg);
              // done();
            }
          });
          this.form.type = 1;
          // done()
        }
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.img = [];
      this.form.name = "";
      this.form.type = "";
      this.form.url = "";
      this.dialogVisible = false;
    },
    handleCloseName() {
      this.$refs.formName.resetFields();
      this.dialogVisibleName = false;
    },
    // 列表
    //图片上传
    uploadFile(res, file) {
      let obj = {
        name: this.removeAfterLastDot(file.name),
        type: this.activeName,
        url: file.response,
      };
      this.imgList.push(obj);
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.img = [...e];
        this.$forceUpdate();
      } else {
        this.form.img = [...this.form.img, e];
        this.$forceUpdate();
      }
    },
    removeAfterLastDot(str) {
      // 找到句点最后一次出现的位置
      const lastDotIndex = str.lastIndexOf(".");

      // 如果找到了句点
      if (lastDotIndex !== -1) {
        // 截取从字符串开始到句点之前的部分
        return str.substring(0, lastDotIndex);
      } else {
        // 如果句点不存在，则直接返回原字符串
        return str;
      }
    },
    getList() {
      this.listLoading = true;
      getSourceMaterialPageList({ ...this.tableFrom, type: this.activeName })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    handleDelete(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sourceMaterialADel(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd() {
      if (this.activeName == 1) {
        this.title = "新增图片";
        let arr = [
          {
            label: "图片",
            row: true,
            type: "text",
            prop: "img",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传图片",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
        ];
        this.option.column = arr;
      } else {
        this.title = "新增视频";
        let arr = [
          {
            label: "视频",
            row: true,
            type: "text",
            prop: "img",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传图片",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
        ];
        this.option.column = arr;
      }
      this.form.id = -null;
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.title = "编辑名称";
      this.formName = JSON.parse(JSON.stringify(row));
      this.dialogVisibleName = true;
    },
  },
};
</script>

<style scoped lang="scss">
.acea-row {
  display: flex;
  flex-wrap: wrap;
}
.bottomBtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.imageBox {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image {
  width: 100%;
}
</style>
